/*eslint-disable*/
import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { io } from 'socket.io-client';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

import { decodeLoginData, getItem, removeAll, setItem } from 'utils/Helper';
import { useFetchOrgTheme, useLogin, useVerifyMFA } from 'api/Auth.api';
import { isValidToken } from 'lib/Jwt';
import { trimmedAndLowerCase } from 'utils/Formatter';

export const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

const getDeviceInfo = () => {
  const navigator_info = window.navigator;
  const screen_info = window.screen;
  let devideId = navigator_info.mimeTypes.length;

  devideId += navigator_info.userAgent.replace(/\D+/g, '');
  devideId += navigator_info.plugins.length;
  devideId += screen_info.height || '';
  devideId += screen_info.width || '';
  devideId += screen_info.pixelDepth || '';

  return devideId;
};

const color = 'color: #00FF00';

export const AuthContextProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [socket, setSocket] = useState(null);
  const [authUser, setAuthUser] = useState(null);

  const { mutate: mutateLogin, isLoading: isLogging } = useLogin();
  const { mutate: mutateTheme, isLoading: isThemeLoading } = useFetchOrgTheme();
  const { mutate: verifyMFA, isLoading: verifyingMFA } = useVerifyMFA();

  const isLoading = isLogging || isThemeLoading;

  useEffect(() => {
    if (isValidToken(getItem(localStorage, 'token'))) {
      setAuthUser({
        userId: getItem(localStorage, 'user_id'),
        role: getItem(localStorage, 'role'),
        userType: getItem(localStorage, 'user_type'),
      });
    }
  }, []);

  useEffect(() => {
    if (isValidToken(getItem(localStorage, 'token'))) {
      connect();
    }

    return () => socket?.disconnect({ close: true });
  }, []);

  const connect = useCallback(() => {
    const socketOption = {
      transports: ['polling', 'websocket'],
      extraHeaders: {
        Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        ['Device-Id']: getDeviceInfo(),
      },
    };

    if (!process.env.REACT_APP_socket_url.includes('http://127.0.0.1')) {
      socketOption.path = '/notification-manager/socket.io';
    }

    const socket = io(process.env.REACT_APP_socket_url, socketOption);

    socket.on('connect', () => {
      queryClient.invalidateQueries(['active-users']);
      console.log('%cSocket connected', color);
    });

    socket.on('disconnect', () => {
      console.log('%cSocket disconnected from server', color);
    });

    socket.on('error', (error) => {
      console.log('Socket Error:', error);
    });

    socket.on('message', (data) => {
      console.log(data);
    });

    setSocket(socket);
  }, []);

  const login = (values, key) => {
    mutateLogin(
      {
        username: trimmedAndLowerCase(values.email),
        password: values.password,
        device_id: getDeviceInfo(),
        recaptcha_code: key,
      },
      {
        onSuccess: (response) => {
          const data = JSON.parse(decodeLoginData(response?.data?.data));

          setItem(localStorage, 'email', values.email);

          if (!data.reset_stts) return navigate('/auth/reset-password');

          if (data.mfa_enables) {
            sessionStorage.setItem('tempToken', data.token);

            return navigate('/auth/verify-mfa');
          }

          setItem(localStorage, 'token', data.token);
          connect(); // comment: connect can't be called before setting jwt token in local storage.

          setAuthUser({
            userId: data?.id,
            role: data?.role,
            userType: data?.user_type,
          });

          setItem(localStorage, 'org_info', JSON.stringify(data.org_info));
          setItem(localStorage, 'role', data?.role.toString());
          setItem(localStorage, 'username', data?.username);
          setItem(localStorage, 'user_id', data?.id.toString());
          setItem(localStorage, 'role_name', data?.role_name);
          setItem(localStorage, 'resource_access', data?.resource_access?.toString());
          setItem(localStorage, 'user_type', data?.user_type);
          setItem(localStorage, 'role_access', data?.role_access.toString());
          setItem(localStorage, 'role_type', data?.role_type.toString());

          if (data?.agency_id) {
            setItem(localStorage, 'agency_id', data?.agency_id.toString());
          } else {
            setItem(localStorage, 'agency_id', '');
          }

          mutateTheme(data.org_info?.id, {
            onSuccess: () => {
              if (state) {
                navigate(state);
              } else {
                navigate('/manager/home');
              }
            },
          });
        },
      },
    );
  };

  const verifyMFACode = (values) => {
    verifyMFA(values, {
      onSuccess: (response) => {
        sessionStorage.removeItem('tempToken');
        const data = JSON.parse(decodeLoginData(response?.data?.data));

        connect();
        setAuthUser({
          userId: data?.id,
          role: data?.role,
          userType: data?.user_type,
        });

        setItem(localStorage, 'token', data.token);
        setItem(localStorage, 'org_info', JSON.stringify(data.org_info));
        setItem(localStorage, 'role', data?.role.toString());
        setItem(localStorage, 'username', data?.username);
        setItem(localStorage, 'user_id', data?.id.toString());
        setItem(localStorage, 'role_name', data?.role_name);
        setItem(localStorage, 'resource_access', data?.resource_access?.toString());
        setItem(localStorage, 'user_type', data?.user_type);
        setItem(localStorage, 'role_access', data?.role_access.toString());
        setItem(localStorage, 'role_type', data?.role_type.toString());

        if (data?.agency_id) {
          setItem(localStorage, 'agency_id', data?.agency_id.toString());
        } else {
          setItem(localStorage, 'agency_id', '');
        }

        mutateTheme(data.org_info?.id, {
          onSuccess: () => {
            if (state) {
              navigate(state);
            } else {
              navigate('/manager/home');
            }
          },
        });
      },
    });
  };

  const logout = () => {
    socket?.disconnect({ close: true });

    removeAll(localStorage);
    navigate('/auth/login');
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isLoading,
        socket,
        authUser,
        verifyMFACode,
        verifyingMFA,
        connect,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
